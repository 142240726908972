import React, { useState, useEffect } from 'react';
import '../login/login.css';
import Container from "react-bootstrap/Container";
import { Carousel, Form, Button, Row, Col, Card } from 'react-bootstrap';
import { FaFacebook, FaGoogle } from 'react-icons/fa'
import login_back from "../../assets/login-back.png"
import { ToastContainer, toast } from "react-toastify";
import LocalIP from "../../LocalIP";
import axios from "axios"
import { useHistory } from "react-router-dom"

const Login = () => {

  let history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [btnDisable, setBtnDisable] = useState(false);

  const clearAll = () => {
    setEmail('')
    setPassword('')
    setEmailError('')
    setPasswordError('')
  }

  const validation = () => {
    var Error = false;

    if (email === "") {
      setEmailError("Email Address Required!")
      Error = true;
    } else {
      setEmailError("")
    }

    if (password === "") {
      setPasswordError("Password Required!")
      Error = true;
    } else {
      setPasswordError("")
    }

    if (Error) {
      return false;
    }

    return true;
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    if (validation()) {
      setBtnDisable(true)
      const url = LocalIP + "user/login";
      const data = JSON.stringify({
        email: email,
        password: password
      });
      console.log(data);
      await axios
        .post(url, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then(async (res) => {
          console.log(res.data);
          setBtnDisable(false)
          if (res.data.err === "connection") {
            toast.error("Network Error!", {
              position: "top-right",
            });
          } else if (res.data.err === "user_email") {
            toast.error("Email Is Wrong!", {
              position: "top-right",
            });
          } else if (res.data.err === "user_active") {
            toast.error("Activation Failed!", {
              position: "top-right",
            });
          } else if (res.data.err === "user_password") {
            toast.error("Password Is Wrong!", {
              position: "top-right",
            });
          } else {
            const current = new Date();
            current.setDate(current.getDate() + 1);
            localStorage.setItem("fname", res.data.fname);
            localStorage.setItem("lname", res.data.lname);
            localStorage.setItem("username", res.data.username);
            localStorage.setItem("email", res.data.email);
            localStorage.setItem("id", res.data.id);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("privilege", res.data.privilege);
            localStorage.setItem("loginAccess", true);
            localStorage.setItem("loginDate", current.toDateString());
            toast.success("Login Successfull!", {
              position: "top-right",
            });
            if (res.data.privilege == "1") {
              history.push("/Admin")
            } else {
              history.push("/Dash")
            }
            window.location.reload(true)
          }
        });

    }
  };

  return (
    <Container>
      <br />
      <div id="login" className="web-view">
        <Row>
          <Col className='col-6'>
            <img
              className="login-img"
              src={login_back}
            />
          </Col>
          <Col className='col-6'>
            <div className="card-view-login hero">
              <Form noValidate onSubmit={SubmitForm} >
                <h2 className="mb-4">Login</h2>
                <FaFacebook className='login-icons' />
                <FaGoogle className='login-icons' />
                <br />
                <Form.Group controlId="formBasicEmail" className="mt-3">
                  <Form.Label className="left-label">Email address</Form.Label>
                  <Form.Control required type="email" placeholder="Enter email" value={email} onChange={e => { setEmail(e.target.value) }} />
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{emailError}</div>
                </Form.Group>
                <Form.Group controlId="formBasicPassword" className="mt-3">
                  <Form.Label className="left-label">Password</Form.Label>
                  <Form.Control required type="password" placeholder="Password" value={password} onChange={e => { setPassword(e.target.value) }} />
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{passwordError}</div>
                </Form.Group>
                <br />
                <Button type="submit" className="mt-4 w-100 btn btn-warning">
                  Login
                </Button>
                <div className="mt-3 ">
                  <a href="/forget-password" className="text-decoration-none">Forget Password?</a>
                </div>
                <br />
                <h6>Don't have an account yet?</h6>
                <a href="/register" className="mt-4 w-100 btn btn-dark">
                  Register
                </a>
              </Form>
            </div>
          </Col>
        </Row>
        <br />
      </div>
      <div id="login" className="mobile-view">
        <div className="card-view-register-mobile">
          <Form noValidate onSubmit={SubmitForm} >
            <h2 className="mb-4">Login</h2>
            <FaFacebook className='login-icons' />
            <FaGoogle className='login-icons' />
            <br />
            <Form.Group controlId="formBasicEmail" className="mt-3">
              <Form.Label className="left-label">Email address</Form.Label>
              <Form.Control required type="email" placeholder="Enter email" value={email} onChange={e => { setEmail(e.target.value) }} />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{emailError}</div>
            </Form.Group>
            <Form.Group controlId="formBasicPassword" className="mt-3">
              <Form.Label className="left-label">Password</Form.Label>
              <Form.Control required type="password" placeholder="Password" value={password} onChange={e => { setPassword(e.target.value) }} />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{passwordError}</div>
            </Form.Group>
            <br />
            <Button type="submit" className="mt-4 w-100 btn btn-warning">
              Login
            </Button>
            <div className="mt-3 ">
              <a href="/forget-password" className="text-decoration-none">Forget Password?</a>
            </div>
            <br />
            <h6>Don't have an account yet?</h6>
            <a href="/register" className="mt-4 w-100 btn btn-dark">
              Register
            </a>
          </Form>
        </div>
        <br />
      </div>
    </Container>
  );
}

export default Login;
