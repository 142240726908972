import React from "react";
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import Image from "react-bootstrap/Image";
import "../navbar/navbar.css"
import { Link } from 'react-router-dom';
import { CgProfile } from "react-icons/cg";

function NavbarComponent() {
  const history = useHistory();

  const Logout = () => {
    localStorage.clear();
    history.push("/");
    window.location.reload(true)
  };

  if (localStorage.getItem("loginAccess") !== "true") {
    return (
      <Navbar expand="lg" className="navbar navbar-expand-lg navbar-custom">
        <Container className="justify-content-between">
          <Navbar.Brand href="/">
            <div id="home" className="web-view">
              <img
                src="/logo color.jpg"
                alt="CodeFlex"
                style={{width:'60px'}}
                className="navbar-site-logo"
              />
              <span className="nav-site-name site-text-color">CodeFlex</span>
            </div>
            <div id="home" className="mobile-view">
              <img
                src="/logo color.jpg"
                alt="CodeFlex"
                style={{width:'60px'}}
                className="navbar-mobile-site-logo"
              />
              <span className="nav-site-name site-text-color">CodeFlex</span>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="collapse navbar-collapse">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item nav-left-li">
                <a className="nav-link site-text-color" aria-current="page" href="/login">
                  Login
                </a>
              </li>
              <li className="nav-item nav-left-li">
                <a className="nav-link site-text-color" href="/Register">
                  Register
                </a>
              </li>
              <li className="nav-item nav-left-li">
                <a className="btn btn-light" href="/login">BOOK NOW</a>
              </li>
            </ul>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  } else {
    return (
      <Navbar expand="lg" className="navbar navbar-expand-lg navbar-custom">
        <Container className="justify-content-between">
          <Navbar.Brand href="/">
            <div id="home" className="web-view">
              <img
                src="/logo color.jpg"
                alt="CodeFlex"
                style={{width:'60px'}}
                className="navbar-site-logo"
              />
              <span className="nav-site-name site-text-color">CodeFlex</span>
            </div>
            <div id="home" className="mobile-view">
              <img
                src="/logo color.jpg"
                alt="CodeFlex"
                style={{width:'60px'}}
                className="navbar-mobile-site-logo"
              />
              <span className="nav-site-name site-text-color">CodeFlex</span>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="collapse navbar-collapse">
            <ul className="navbar-nav ms-auto">
              <Nav className="ml-auto">
                <NavDropdown
                  title={
                    <span>
                      <CgProfile className="nav-user-profile-icon" />{' '}
                      {localStorage.getItem("fname")} {localStorage.getItem("lname")}
                    </span>
                  }

                >
                  <div className="nav-profile-dropdown">
                    <NavDropdown.Item className="nav-user-dropdown-item" href="#profile-settings">Profile Settings</NavDropdown.Item>
                    <NavDropdown.Item className="nav-user-dropdown-item" href="/my_ads">My Ads</NavDropdown.Item>
                    <NavDropdown.Item className="nav-user-dropdown-item" href="/my_favorite">My Favorites</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item className="nav-user-dropdown-item" onClick={Logout}>Logout</NavDropdown.Item>
                  </div>
                </NavDropdown>
              </Nav>
              <li className="nav-item nav-left-li">
                <a className="btn btn-light" href="/post_ads">BOOK NOW</a>
              </li>
            </ul>
          </Navbar.Collapse>
        </Container>
      </Navbar >
    );
  }
};

export default NavbarComponent;
