// Footer.js
import React from 'react';
import '../footer/footer.css';
import { Container, Row, Col } from 'react-bootstrap';
import { FaPhoneAlt, FaHome, FaEnvelope, FaFacebook, FaTwitter, FaLinkedinIn } from 'react-icons/fa'
import { TbWorld } from 'react-icons/tb'

const Footer = () => {
  return (
    <footer>
      <div className="footer text-light p-4">
        <Container className="mt-4">
          <Row className='py-3'>
            <Col className="text-left" md={6}>
              <h5>About CodeFlex</h5>
              <p className='footer-about-p site-text-color'>At CodeFlex, we are passionate about building innovative, scalable, and high-performance software solutions that empower businesses and individuals alike. With a team of highly skilled professionals, we specialize in a wide array of services, including custom software development, web and mobile applications, cloud solutions, and cutting-edge technologies like AI, machine learning, and IoT.

We pride ourselves on our ability to tailor solutions to meet our clients' unique needs, driving their growth and efficiency. Whether you're a startup looking to launch a new product or an established business aiming to streamline operations, CodeFlex is your trusted partner for turning ideas into reality.</p>
              <ul className="list-unstyled">
                <li><FaHome className="contact-icon footer-contact-icons" /><span className='site-text-color'>No 683,Anagarika Dharmapala M/W,Walgama,Matara, SP 81000,Sri Lanka</span></li>
                <li><a href="mailto:support@codeflexofficial.com" className="d-flex align-items-center footer-contact-a site-text-color"><FaEnvelope className="contact-icon footer-contact-icons" />Email: support@codeflexofficial.com</a></li>
                <li><a href="tel:+94712129185" className="d-flex align-items-center footer-contact-a site-text-color"><FaPhoneAlt className="contact-icon footer-contact-icons" /> Phone: +94 71 21 29 185</a></li>
              </ul>
            </Col>
            <Col className="text-left" md={3}>
              <h5>CATEGORIES</h5>
              <ul className="list-unstyled">
                <li><a href="/faq" className="footer-link site-text-color">Mobile Appliances</a></li>
                <li><a href="/faq" className="footer-link site-text-color">Web Appliances</a></li>
                <li><a href="/faq" className="footer-link site-text-color">PC Appliances</a></li>
                <li><a href="/faq" className="footer-link site-text-color">Any Other Softwares</a></li>
                <li><a href="/faq" className="footer-link site-text-color">Devices</a></li>
              </ul>
            </Col>
            <Col className="text-left" md={3}>
              <h5>QUICK LINKS</h5>
              <ul className="list-unstyled">
                <li><a href="/about-us" className="footer-link site-text-color">Home</a></li>
                <li><a href="/terms" className="footer-link site-text-color">Products</a></li>
                <li><a href="/privacy-policy" className="footer-link site-text-color">Portfolio</a></li>
                <li><a href="/privacy-policy" className="footer-link site-text-color">About Us</a></li>
                <li><a href="/privacy-policy" className="footer-link site-text-color">Contact Us</a></li>
              </ul>
            </Col>
          </Row>
          <hr />
          <div>
            <Row className="d-flex align-items-center">
              <Col md={6} className="text-left">
                <a href='https://www.linkedin.com/company/wizolve-technologies/'><p className="mb-0 site-text-color">Copyright © {new Date().getFullYear()} All Rights Reserved by CodeFlex.</p></a>
              </Col>
              <Col md={6} className="text-right d-flex justify-content-end align-items-center">
                <FaFacebook className="contact-icon footer-social-icons" />
                <FaTwitter className="contact-icon footer-social-icons" />
                <TbWorld className="contact-icon footer-social-icons" />
                <FaLinkedinIn className="contact-icon footer-social-icons" />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
