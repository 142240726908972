import React, { useState, useEffect } from 'react';
import '../home/home.css';
import { Carousel, Form, FormControl, Button, InputGroup, Row, Col, Card } from 'react-bootstrap';
import Container from "react-bootstrap/Container";
import { FaSearch, FaRegStar } from 'react-icons/fa'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LocalIP from "../../LocalIP";
import axios from "axios"
import { useHistory } from 'react-router-dom';

const Home = () => {

    const [machinery, setMachinery] = useState([]);
    const [search, setSearch] = useState('');
    const [categorySearch, setCategorySearch] = useState('');
    const [areaSearch, setAreaSearch] = useState('');

    useEffect(() => onReload(), []);
    const history = useHistory();

    const onReload = () => {
        console.log("new")
        // const url = LocalIP + "machinery/";
        // axios.get(url).then((response) => {
        //     console.log(response["data"])
        //     setMachinery(response["data"])
        // });
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        autoplay: true,
        slidesToScroll: 1
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        var queryParams = ""
        if (search !== "") {
            if (categorySearch !== "") {
                if (areaSearch !== "") {
                    queryParams = new URLSearchParams({
                        search: search,
                        category: categorySearch,
                        area: areaSearch
                    }).toString();
                } else {
                    queryParams = new URLSearchParams({
                        search: search,
                        category: categorySearch
                    }).toString();
                }
            } else {
                if (areaSearch !== "") {
                    queryParams = new URLSearchParams({
                        search: search,
                        area: areaSearch
                    }).toString();
                } else {
                    queryParams = new URLSearchParams({
                        search: search
                    }).toString();
                }
            }
        } else {
            if (categorySearch !== "") {
                if (areaSearch !== "") {
                    queryParams = new URLSearchParams({
                        category: categorySearch,
                        area: areaSearch
                    }).toString();
                } else {
                    queryParams = new URLSearchParams({
                        category: categorySearch
                    }).toString();
                }
            } else {
                if (areaSearch !== "") {
                    queryParams = new URLSearchParams({
                        area: areaSearch
                    }).toString();
                } else {
                    history.push(`/all_ads`);
                }
            }
        }
        history.push(`/all_ads?${queryParams}`);
    };

    return (
        <div>
            
        </div>
    );
}

export default Home;
