
import "./App.css"
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "../src/Components/navbar/navbar";
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Footer from "../src/Components/footer/footer.js";
import Home from "../src/Components/Pages/home/home.js";
import Login from "../src/Components/Pages/login/login.js";
import Register from "../src/Components/Pages/register/register.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    <BrowserRouter>
      <div className="overlay">

        <Navbar />
        <ToastContainer toastStyle={{ marginTop: "80px" }} />
        <div className="App" style={{ minHeight: 88 + "vh" }}>
          <Switch>
            <Route path="/Register" render={() => <Register />} />
            <Route path="/login" render={() => <Login />} />
            <Route path="/" render={() => <Home />} />
          </Switch>
        </div>
        <Footer />
      </div>

    </BrowserRouter>
  );
}

export default App;
